import React, { useEffect, useState } from "react";
import {
  Spinner,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Text,
  Box,
} from "@chakra-ui/react";
import Anime from "../animations/Anime";
import { ChakraProvider } from "@chakra-ui/react";
import { IoClose } from "react-icons/io5";
import SimpleCountdownTimer from "../SimpleCountDownTimer";

const CustomModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  isRequired,
  isError,
  hasSubscribed,
  duration = 10000,
  onTimerComplete = () => console.log("custom modal timer complete"),
  startCount = false,
}) => {
  // const colorList = [
  //   "rgb(255, 193, 122, 0.9)",
  //   "rgb(255, 89, 148, 0.9)",
  //   "rgb(255, 23, 83, 0.9)",
  //   "rgb(255, 142, 108, 0.9)",
  //   "rgb(255, 155, 133, 0.9)",
  //   "rgb(255, 28, 91, 0.9)",
  //   "rgb(255, 52, 78, 0.9)",
  // ];
  // const [colorIndex, setColorIndex] = useState(0);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setColorIndex((colorIndex + 1) % colorList.length);
  //   }, 200);
  //   return () => clearInterval(intervalId);
  // }, [colorIndex, colorList.length]);

  const [showCounter, setShowCounter] = useState(false);
  const [startCountdown, setStartCountDown] = useState(startCount);
  // Quand la modal est ouverte, montrer le compteur
  useEffect(() => {
    if (isOpen) {
      // setShowCounter(true);
      // setStartCountDown(startCount); // Démarrer le compte à rebours si startCount est vrai
    } else {
      setShowCounter(false); // Cacher le compteur si la modal est fermée
    }
  }, [isOpen, startCount]);

  return (
    <ChakraProvider>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="xs">
        <ModalOverlay
          bg="blackAlpha.100"
          // backdropFilter="blur(10px) hue-rotate(10deg)"
        />
        <ModalContent bg="transparent">
          <Box
            // bg={!isRequired ? colorList[colorIndex] : "rgba(242,170,132,0.87)"}
            bg={"rgba(242,170,132,0.87)"}
            color="#FFFFFF"
            sx={{
              alignItems: "center",
              borderRadius: "50px",
            }}
          >
            {isRequired && (
              <ModalCloseButton color="#FFFFFF">
                <IoClose />
              </ModalCloseButton>
            )}

            <ModalBody
              p={5}
              sx={{
                alignItems: "center",
              }}
            >
              {!isRequired ? (
                <Text
                  sx={{
                    fontSize: "28px",
                    marginTop: "30px",
                    textAlign: "center",
                  }}
                >
                  Félicitations ! <br /> Dans quelques instants vous allez
                  découvrir la playlist avec les titres les plus demandés
                </Text>
              ) : (
                <Text
                  sx={{
                    fontSize: "28px",
                    marginTop: "30px",
                    textAlign: "center",
                  }}
                >
                  Pour valider votre playlist, vous devez sélectionner tous les
                  titres
                </Text>
              )}
            </ModalBody>

            {/* {hasSubscribed && <Anime />} */}
          </Box>
          {!isRequired && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "#fff",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                marginTop: "20px",
              }}
            >
              {/* {!isRequired && showCounter && <SimpleCountdownTimer start={startCountdown} onTimerComplete={()=>onTimerComplete()} duration={duration} size={80}/>}
         { showCounter && <p style={{
            textAlign:"center",
            maxWidth: "150px"
          }}>
            Avant de découvrir la playlist définitive
          </p>} */}
              <Spinner
                thickness="20px"
                speed="0.65s"
                textAlign={"center"}
                // color="rgba(219,97,47,0.75)"
                color="rgba(219, 97, 47, 0.75)"
                size="xl"
              />
            </div>
          )}
          {isError && (
            <Text
              sx={{
                fontSize: "15px",
                textAlign: "center",
                color: "white",
              }}
            >
              Impossible de voter
            </Text>
          )}
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default CustomModal;
