import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import ReactPlayer from "react-player";
import "./video.css";
import BackgroundImage from "../BackgroundImage ";
import ImageBackground from "../assets/Jukebox_mobile_black.jpg";
import ImageBackgroundWeb from "../assets/jukebox_black.jpg";
import { Box, Typography, Button } from "@mui/material";
import { FetchedData } from "../dataSong";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import useSWR from "swr";
import FullscreenVideo from "../components/FullscreenVideo";
import OnVideoModal from "../components/modal/onVideoModal";
import CountdownTimer from "../components/CountdownTimer";
import { Text } from "@chakra-ui/react";
import SimpleCountdownTimer from "../components/SimpleCountDownTimer";

const VideoPage = () => {
  const [playerstate, setPlayerState] = useState({
    playing: true,
    mute: true,
    volume: 0.5,
    playerbackRate: 1.0,
    played: 0,
    seeking: false,
  });
  const [videoContent, setVideoContent] = useState(null);
  const [endTime, setEndTime] = useState(new Date().getTime() + 1 * 15 * 1000);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const fetcher = (...args) => fetch(...args).then((res) => res.json());
  const url = `${process.env.REACT_APP_DOMAIN}/api/endVoteTime`;

  const [dateTimestamp, setDateTimeStamp] = useState({
    end: null,
    start: null,
  });
  const { logout } = useAuth();

  const { data, error, isLoading } = useSWR(url, fetcher, {
    revalidateOnFocus: true,
  });
  if (data?.result === true) {
    logout();
  }
  if (data?.gotCode !== undefined) {
    if (data?.gotCode !== localStorage.getItem("currentpass")) {
      // console.log("current password:", localStorage.getItem("currentpass"));
      // console.log("passfrom store:", data?.gotCode);
      // console.log("date reached from pass");
      logout();
    }
  }

  const fetchedData = async () => {
    try {
      let content = localStorage.getItem("videoContent");
      // let content =
      //   "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4";
      content.replace(/\/+$/, "");
      setVideoContent(`${process.env.REACT_APP_DOMAIN}/static/${content}`);
      const results = await FetchedData();
      const originalDate = results.playListInfo.dateFinVote;
      const dateDebutVote = results.playListInfo.dateDebutVote;
      const dateFinVote = results.playListInfo.dateFinVote;
      setDateTimeStamp({
        end: new Date(dateFinVote).getTime(),
        start: new Date(dateDebutVote).getTime(),
      });
      setEndTime(moment(originalDate.toString().slice(0, -1)).utc().valueOf());
    } catch (error) {
      console.error("Internal occured error : ", error);
    }
  };
  useEffect(() => {
    fetchedData();
  }, []);

  const [isReady, setIsReady] = useState(false);
  const [years, setYear] = useState("2025");
  const handleOnReady = () => {
    setIsReady(true);
  };
  const getScreenWidth = () => {
    if (window.innerHeight < 670) {
      return "50%";
    } else if (window.innerHeight <= 740) {
      return "65%";
    } else if (window.innerHeight <= 844) {
      return "70%";
    } else if (window.innerHeight <= 896) {
      return "80%";
    } else if (window.innerHeight <= 932) {
      return "85%";
    } else {
      return "100%";
    }
  };
  const navigate = useNavigate();
  const handleOnclickClose = () => {
    navigate("/finalplaylist");
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const remaining = endTime - currentTime;
      if (remaining < 1000) navigate("/finalplaylist");
      setTimeRemaining(remaining);
    }, 1000); // Mettre à jour toutes les secondes

    return () => clearInterval(interval); // Nettoyer le setInterval lorsque le composant est démonté
  }, [endTime]); // [endTime] pour réinitialiser l'interval lorsque l'heure de fin change

  const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
  const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
  const seconds = Math.floor((timeRemaining / 1000) % 60);

  const formattedTime = () => (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {hours !== 0 && (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: "larger", height: "33px" }}>
              {("0" + hours).slice(-3)}
            </span>
            <span style={{ fontSize: "xx-small" }}>hour</span>
          </div>
          <span style={{ margin: "0px 5px" }}>:</span>
        </>
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: "larger", height: "33px" }}>
          {("0" + minutes).slice(-2)}
        </span>
        <span style={{ fontSize: "xx-small" }}>mins</span>
      </div>
      <span style={{ margin: "0px 5px" }}>:</span>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: "larger", height: "33px" }}>
          {("0" + seconds).slice(-2)}
        </span>
        <span style={{ fontSize: "xx-small" }}>secs</span>
      </div>
    </div>
  );
  // console.log("pause state:", isPlaying);
  return (
    <BackgroundImage
      imageUrlMobile={ImageBackground}
      imageUrlDesktop={ImageBackgroundWeb}
    >
      <div style={{ position: "relative", height: "100vh", width: "100vw" }}>
        <FullscreenVideo
          url={videoContent}
          reactPlayerProps={{
            onReady: () => handleOnReady(),
            muted: true,
            loop: false,
            playsinline: true,
            playing: true,
            autoPlay: true,
            controls: true,
          }}
        />
        <Button
          sx={{
            position: "absolute",
            zIndex: 99,
            top: "6%",
            right: "10px",
            fontSize: "x-large",
            color: "white",
          }}
          onClick={handleOnclickClose}
        >
          X
        </Button>
        <Text
          className="din"
          sx={{
            fontSize: "28px",
            marginTop: "30px",
            textAlign: "center",
            position: "absolute",
            top: "30%",
            left: "50%",
            color: "#fff",
            width: "100%",
            transform: "translate(-50%,50%)",
            zIndex: 200,
          }}
        >
          ÊTES-VOUS PRÊTS ?
        </Text>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: "#fff",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            marginTop: "20px",
            position: "absolute",
            bottom: "20%",
            left: "50%",
            transform: "translate(-50%,50%)",
            zIndex: 200,
          }}
        >
           {dateTimestamp.end && dateTimestamp.start ?
              <CountdownTimer showText={false} onTimerComplete={()=>console.log("finish videopage")} size={80} strokeWidth={3} startTime={dateTimestamp.start} endTime={dateTimestamp.end}/>:
              <SimpleCountdownTimer start  duration={15000} size={80}/>}

          <p
            style={{
              textAlign: "center",
              maxWidth: "150px",
            }}
          >
            Avant de découvrir la playlist définitive
          </p>
        </div>
      </div>

      {/* <Box>
          <Typography
            variant="h2"
            style={{
              color: "rgb(251, 221, 46, 0.9)",
              textAlign: "center",
              textShadow: "rgb(0, 0, 0) 1px 1px 7px",
            }}
          >
            <span className="budmo">JUKE BOX</span>
          </Typography>
          <Typography
            variant="h2"
            style={{
              color: "#fff",
              textAlign: "center",
              marginTop: -28,
              marginBottom: 10,
            }}
          >
            <span
              className="budmo"
              style={{
                color: "rgb(251, 221, 46, 0.9)",
                textShadow: "rgb(0, 0, 0) 1px 1px 7px",
              }}
            >
              TOUR <span className="octothorpe">{years}</span>
            </span>
          </Typography>
        </Box> */}
      {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-1rem",
            marginBottom: "10px",
          }}
        >
          <Typography
            variant="h5"
            component="h4"
            sx={{
              background: "#FFFFFF",
              padding: 1,
              borderRadius: 2,
              color: "rgb(255, 142, 108)",
              textAlign: "center",
              width: "100%",
            }}
          >
            <span className="scuba">{formattedTime()}</span>
          </Typography>
        </Box> */}
      {/* <Typography
          variant="h7"
          component="h6"
          style={{ color: "#fff", textAlign: "center" }}
          gutterBottom
        >
          <span>La playlist définitive sera accessible</span>
          <br />
          <span>dès la fin des votes!</span>
        </Typography> */}
      {/* <Box>
          <Container
            sx={{
              padding:"0px",
              margin:"0px"
            }}
          >
            <div 
              //className="playerDiv"
              style={{
                position:"relative",
                top:0,
                left:0,
                minHeight:"100vh",
                minWidth: "100vw",
              }}

            >
              <ReactPlayer
                playsinline={true}
                playing={true}
                autoplay={true}
                // className="player"
             //   width={getScreenWidth()}
             //   height="auto"
                controls={true}
                width="100%"
                height="100%"
                onReady={handleOnReady}
                url={videoContent}
                muted
                loop={false}
                
                
              />
            </div>
          </Container>
      </Box> */}

      {/* <OnVideoModal dateTimestamp={dateTimestamp} isOpen={true} onTimerComplete={()=>console.log("Video complete")}/> */}
    </BackgroundImage>
  );
};

export default VideoPage;
